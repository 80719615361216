import React from "react";
import { LayoutDefault } from "../../components/LayoutDefault";
import { MoveHack } from "../../components/Services";
import {
  ButtonPrimaryLink,
  CenteredContentWrapper,
  ConfettiBigSectionWrapper,
  device,
  EmphasizedText,
  Subheading,
  TestersIcon,
  TextBody,
  TextToutFullwidth,
  ThumbnailHeader,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
} from "@xolvio/xolvio-ui";
import {
  Header,
  HeaderBody,
  HeaderContentWrapper,
  Subheader,
} from "./experience-graph-managed-services";
import { StyledImg } from "./rapid-backend-development";
import { TwoColumnSectionHeader } from "../../components/Services/ServicePageTemplate";
import styled from "styled-components";
import { AlternateBackground, ToutRow } from "../../components/Homepage";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { SectionHeader } from "@xolvio/xolvio-ui/src";
import { contactUsFooterProps } from "../../pages-content/homepage";
import { OtherServices } from "../../components/Services/OtherServices";

const GraphqlSecurityReviewRemediation = () => (
  <LayoutDefault>
    <div>
      <MoveHack topMobile={"-50px"} />
      <ConfettiBigSectionWrapper
        hideMobileConfetti
        style={{
          backgroundColor: "#ffffff",
          height: "220px",
          marginBottom: "30px",
          zIndex: 100,
        }}
      >
        <HeaderContentWrapper>
          <TestersIcon height="100px" width="100px" />
          <Subheader>SERVICES / DIGITAL EXPERIENCE INTEGRATION</Subheader>
          <Header style={{ maxWidth: "100% !important", textAlign: "center" }}>
            GraphQL Security Review & Remediation
          </Header>
          <HeaderBody>
            We help you secure your GraphQL APIs leveraging best-in-class
            tooling from Escape.
          </HeaderBody>
        </HeaderContentWrapper>
      </ConfettiBigSectionWrapper>

      <MoveHack top={"50px"} topMobile={"120px"} />

      <TwoColumnsWithImage
        imagePosition={"right"}
        imageNode={
          <StyledImg
            src={"/assets/images/escape-logo-mono.svg"}
            width="500px"
          />
        }
        contentNode={
          <TwoColumnContentNodeWrapper>
            <Subheading>OUR SECURITY PARTNER</Subheading>
            <TwoColumnSectionHeader>
              <EmphasizedText
                highlight={"automation"}
                text={"Best-in-class GraphQL security automation"}
              />
            </TwoColumnSectionHeader>
            <Text>
              Escape’s tooling automatically detects application vulnerabilities
              and provides tailored remediation recommendations. Further,
              integrating the platform into a CI/CD pipeline allows for
              continued security compliance.
            </Text>
            <MoveHack topMobile={"50px"} />
            <ButtonPrimaryLink as={"a"} href={"https://escape.tech/"}>
              Find out more
            </ButtonPrimaryLink>
            <MoveHack topMobile={"50px"} />
          </TwoColumnContentNodeWrapper>
        }
      />

      <MoveHack topMobile={"100px"} />

      <AlternateBackground>
        <CenteredContentWrapper>
          <ContentWrapper>
            <Subheading style={{ textAlign: "center" }}>
              {"HOW WE DO IT"}
            </Subheading>
            <SectionHeader style={{ maxWidth: "1200px", textAlign: "center" }}>
              {"GraphQL Security Review & Remediation"}
            </SectionHeader>
            <TextBody style={{ maxWidth: "100%", textAlign: "left" }}>
              As an official remediation partner, we leverage Escape’s tooling
              for runtime security review purposes and provide remediation
              implementation services to secure your APIs.
            </TextBody>

            <MoveHack top={"50px"} />

            <StyledToutRow>
              <div>
                <ThumbnailHeader>{"Runtime security review"}</ThumbnailHeader>
                <TextBody style={{ textAlign: "left" }}>
                  {
                    "Using the Escape platform, we perform runtime security scanning for you to check for API vulnerabilities."
                  }
                </TextBody>
              </div>
              <RowItem>
                <ThumbnailHeader>
                  {"Architecture security review"}
                </ThumbnailHeader>
                <TextBody style={{ textAlign: "left" }}>
                  {
                    "Leveraging our profound software architecture expertise, we investigate your system for architectural flaws impacting API security."
                  }
                </TextBody>
              </RowItem>
            </StyledToutRow>
            <MoveHack top={"-50px"} topMobile={"10px"} />

            <StyledToutRow>
              <div>
                <ThumbnailHeader>
                  {"Vulnerability remediation implementation"}
                </ThumbnailHeader>
                <TextBody style={{ textAlign: "left" }}>
                  {
                    "We combine the results of both the runtime and architecture security reviews, and swiftly implement vulnerability remediation for you."
                  }
                </TextBody>
              </div>
              <RowItem>
                <ThumbnailHeader>
                  {"Continued security compliance"}
                </ThumbnailHeader>
                <TextBody style={{ textAlign: "left" }}>
                  {
                    "API security needs to be an ongoing concern. We integrate Escape’s platform into your CI/CD pipeline to ensure that security issues are identified and addressed before they reach production."
                  }
                </TextBody>
              </RowItem>
            </StyledToutRow>
          </ContentWrapper>
        </CenteredContentWrapper>
      </AlternateBackground>

      <OtherServices
        services={[
          "Apollo GraphQL Consulting",
          "Experience Graph Managed Services",
          "SDLC & Architecture Consulting",
          "Rapid Backend Development",
          "Legacy Systems Modernization",
        ]}
      />

      <TextToutFullwidth {...contactUsFooterProps} />
    </div>
  </LayoutDefault>
);

export default GraphqlSecurityReviewRemediation;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;

const StyledToutRow = styled(ToutRow)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  & > div {
    flex: 1;
    * {
      max-width: unset;
    }
  }
`;

const RowItem = styled.div`
  @media ${device.mobile} {
    margin-top: -50px;
  }
`;
